<template>
  <el-dropdown trigger="click" @command="changeLanguage">
    <span class="el-dropdown-link">
      <el-icon class="el-icon--right"><img src="@/assets/homePage/lang.svg" class="SvgImg"></el-icon>
    </span>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item command="zh" >中文</el-dropdown-item>
        <el-dropdown-item command="en" >English</el-dropdown-item>
        <el-dropdown-item command="ja" >日本語</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>
<script>

export default {
    data() {
      return {
        url:null
      }
    },
    watch:{
      $route(to,from){
        // this.url =   to.path.split('/')[1]
        this.$i18n.locale = to.path.split('/')[1]
      },
       deep: true
    },
  methods: {

    changeLanguage(lang) {
      let url=window.location.href
      url = url.replace("ja", lang)
      url = url.replace("en", lang)
      url = url.replace("zh", lang)
      window.location.href = url
      
      localStorage.setItem('lang', lang)// 切换多语言
      //   Cookie.set('lang', lang) 
      this.$i18n.locale = lang // 设置给本地的i18n插件
      this.$message.success(this.$t('设置成功'))

    }
  }
}
</script>

<style scoped lang="less">
.el-icon--right {
  margin-top: 5px;
}

.SvgImg {
  width: 25px;
  background-color: white;
}
</style>